<template>
  <div :class="['container', { 'is-zero': size === sizes.ZERO }, classes]">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

const sizes = {
  NORMAL: "normal",
  ZERO: "zero",
};

export enum Positions {
  Left = "left",
  Center = "center",
}

export default defineComponent({
  name: "MyContainer",
  props: {
    size: {
      type: String,
      default: sizes.NORMAL,
    },
    position: {
      type: String as PropType<Positions>,
      default: Positions.Center,
    },
  },
  data() {
    return {
      sizes,
      classes: {
        "is-left": this.position === Positions.Left,
      },
    };
  },
});
</script>

<style lang="postcss" scoped>
.container {
  @apply mx-auto;

  &.is-zero {
    @apply px-0;
  }

  &.is-left {
    @apply mx-0;
  }

  @apply px-4 max-w-full;
  @apply sm:max-w-screen-md;
  @apply md:max-w-screen-lg;
  @apply lg:max-w-screen-xl;
  /* @apply xl:max-w-screen-2xl; */
}
</style>
