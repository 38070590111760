import { http, newCtxConfig } from "@src/http";
import { Module } from "vuex";

const _default_user = {
  ID: null,
  type: null,
  email: null,
  mobile: null,
  userName: null,
};

export const state = {
  token: "",
  user: _default_user,
};

export enum Mutations {
  SET_TOKEN = "setToken",
  SET_USER = "setUser",
  CLEAR_ALL_DATA = "clearAllData",
}

export enum Actions {
  DO_PASSWORD_LOGIN = "doPasswordLogin",
  DO_REGISTER = "doRegister",
  DO_LOGOUT = "doLogout",
}

export const MODULE_NAME = "account";

export const account: Module<typeof state, unknown> = {
  namespaced: true,
  state: () => state,
  getters: {
    isLogged(state) {
      return state.token !== "";
    },
  },
  actions: {
    [Actions.DO_REGISTER]({ commit }, { username, mobile, email, password }) {
      return http.client
        .post(
          "/users",
          {
            username,
            mobile,
            email,
            password,
          },
          newCtxConfig({
            action: Actions.DO_REGISTER,
          })
        )
        .then(
          ({ data: user }) => {
            commit(Mutations.SET_USER, user);
            return user;
          },
          (err) => {
            console.debug("Register Fail: ", err.response);
            return null;
          }
        );
    },

    [Actions.DO_PASSWORD_LOGIN]({ commit }, { username, password }) {
      return http.client
        .post(
          "users/login",
          {
            username: username,
            password: password,
          },
          newCtxConfig({
            action: Actions.DO_PASSWORD_LOGIN,
            id: Actions.DO_PASSWORD_LOGIN,
          })
        )
        .then(
          ({ data: jwt }) => {
            http.useToken(jwt);
            http.saveToken(jwt);
            commit(Mutations.SET_TOKEN, jwt);
            return jwt;
          },
          (err) => {
            console.debug("Login by Password Fail: ", err.response);
            return null;
          }
        );
    },

    [Actions.DO_LOGOUT]({ commit }) {
      http.deleteToken();
      commit(Mutations.CLEAR_ALL_DATA);
    },
  },
  mutations: {
    [Mutations.SET_TOKEN](state, token) {
      state.token = token;
    },
    [Mutations.SET_USER](state, user) {
      state.user = user;
    },
    [Mutations.CLEAR_ALL_DATA]() {
      state.token = "";
      state.user = _default_user;
    },
  },
};
