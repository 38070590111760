<template>
  <div class="card">
    <div class="card-header"><slot name="header"></slot></div>
    <div class="card-body">
      <slot></slot>
    </div>
    <div class="card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MyCard",
});
</script>

<style lang="postcss" scoped>
.card {
  @apply bg-light shadow-light rounded;

  & .card-header {
    @apply p-2 sm:p-4 pb-0 sm:pb-0 mb-2 sm:mb-4;
    @apply text-size-2 sm:text-size-3 font-bold cursor-pointer;
  }

  & .card-body {
    @apply px-2 sm:px-4 my-2 sm:my-4;
    @apply text-sm;
  }

  & .card-footer {
    & :deep(.card-button) {
      @apply w-1/3;
      @apply border-neutral-200 rounded-none first:rounded-b last:rounded-b first:rounded-r-none last:rounded-l-none first:border-r-0 last:border-l-0;
      @apply border-b-0 first:border-l-0 last:border-r-0;

      & .label {
        @apply font-semibold;
      }
    }
  }
}
</style>
