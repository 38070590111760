import "@src/styles/_global.pcss";
import "@src/assets/icons";

import { createApp } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// @ts-ignore
import Toaster from "@meforma/vue-toaster";

import { store } from "./store";
import { Mutations, MODULE_NAME } from "./store/modules/account";
import { router } from "./router";
import { http } from "./http";
import { i18n } from "./i18n";
import Root from "./Root.vue";

http.withStore(store);
const ok = http.loadTokenFromStorage();
if (ok) {
  store.commit(`${MODULE_NAME}/${Mutations.SET_TOKEN}`, http.loadedToken);
}

const root = createApp(Root);
root.use(store).use(router).use(i18n).use(Toaster, {position: 'top', maxToasts: 3});
root.component("FaIcon", FontAwesomeIcon);
root.mount("#root");
