const emailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const mediumPasswordRe = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/;
const usernameRe = /^(?=[a-zA-Z0-9]{5,20}$)/;
const phoneRe = /^(?=[0-9]{10}$)/;

export const isEmailValid = (val: string) => !!val && emailRe.test(val.toLowerCase());
export const isMediumPassword = (val: string) => !!val && mediumPasswordRe.test(val);
export const isUsernameValid = (val: string) => !!val && usernameRe.test(val);
export const isPhoneValid = (val: string) => !!val && phoneRe.test(val);
