<template>
  <component :is="tag" :class="['heading', classes]">
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent } from "vue";

enum Sizes {
  MD = "md",
  LG = "lg",
  XL = "xl",
}

export default defineComponent({
  name: "MyHeading",
  props: {
    size: {
      type: String,
      default: Sizes.MD,
    },
    tag: {
      type: String,
      default: "h3",
    },
  },
  data() {
    return {
      classes: {
        md: this.size == Sizes.MD,
        lg: this.size == Sizes.LG,
        xl: this.size == Sizes.XL,
      },
    };
  },
});
</script>

<style lang="postcss" scoped>
.heading {
  /* Base */
  @apply text-dark font-display font-bold;

  /* Size - MD */
  &.md {
    @apply text-size-3; /* mobile */
    @apply sm:text-size-4; /* tablet */
  }

  /* Size - LG */
  &.lg {
    @apply text-size-4; /* mobile */
    @apply sm:text-size-5; /* tablet */
  }

  /* Size - XL */
  &.xl {
    @apply text-size-5; /* mobile */
    @apply sm:text-size-6; /* tablet */
  }
}
</style>
