<template>
  <div class="layout-main">
    <MyHeader />
    <div class="layout-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import MyHeader from "./Header.vue";
import { defineComponent } from "vue";

import LHeader from "./LHeader.vue";
// import LFooter from "./LFooter.vue";

export default defineComponent({
  name: "LMain",
  components: {
    MyHeader,
  },
});
</script>

<style lang="postcss" scoped>
.layout-main {
  @apply flex flex-col min-h-screen;

  & .layout-body {
    @apply flex-grow;
  }
}
</style>
