<template>
  <MyCard class="card-pass">
    <template #header>
      <div class="pass-identity">
        <span class="pass-sharp">#</span>
        <span class="pass-serial">{{ pass.serial }}</span>
        <div class="pass-status"><FaIcon icon="mobile" /> registered</div>
      </div>
    </template>
    <div class="pass-data">
      <template v-for="(val, key) in pass.paramValues" :key="key">
        <div class="data-key"><span class="data-type">string</span> {{ key }}</div>
        <div class="data-value">{{ val }}</div>
      </template>
    </div>
    <div class="pass-info">
      <div class="pass-created-at"><FaIcon icon="clock" /> 2020-07-21 07:24:06</div>
    </div>
  </MyCard>
</template>

<script lang="ts" setup>
import MyCard from "@src/components/Card.vue";
</script>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Pass } from "@src/models/passes";

export default defineComponent({
  name: "CardPass",
  props: {
    pass: {
      type: Object as PropType<Pass>,
      required: true,
    },
  },
});
</script>

<style lang="postcss" scoped>
.card-pass {
  & .pass-identity {
    & .pass-sharp {
      @apply text-xs text-gray-400;
    }
    & .pass-serial {
      @apply ml-2;
    }
    & .pass-status {
      @apply text-xs text-green-500;
    }
  }

  & .pass-data {
    @apply grid grid-cols-[min-content,auto] gap-x-4 mb-2 sm:mb-4;

    & .data-key {
      @apply font-bold whitespace-nowrap;

      & .data-type {
        @apply text-xs text-gray-400;
      }
    }
    & .data-value {
      @apply mb-1 border-b border-gray-500 border-dotted sm:mb-2;
    }
  }

  & .pass-info {
    & .pass-created-at {
      @apply mb-1 text-sm;
    }
  }
}
</style>
