<template>
  <MyCard class="card-pass-template">
    <template #header>{{ template.description }}</template>
    <template #footer>
      <router-link :to="`/app/templates/${template.ID}/passes`">
        <MyButton skin="normal" kind="outlined" class="card-button" size="small"
          ><FaIcon icon="swatchbook"></FaIcon><br />Passes</MyButton
        >
      </router-link>
      <MyButton skin="normal" kind="outlined" class="card-button" size="small"
        ><FaIcon icon="tools"></FaIcon><br />Edit</MyButton
      >
      <MyButton skin="normal" kind="outlined" class="card-button" size="small">
        <FaIcon icon="trash"></FaIcon><br />Remove</MyButton
      >
    </template>
    <div class="card-pass-template-body">
      <span>{{ template.fields.length }} fields</span>
      <span class="text-green-500">public registration</span>
    </div>
  </MyCard>
</template>

<script lang="ts" setup>
import MyCard from "@src/components/Card.vue";
import MyButton from "@src/components/Button.vue";
</script>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { PassTemplate } from "@src/models/passes";

export default defineComponent({
  name: "CardPassTemplate",
  props: {
    template: { type: Object as PropType<PassTemplate>, required: true },
    totalPasses: { type: Number, default: 0 },
  },
});
</script>

<style lang="postcss" scoped>
.card-pass-template {
  & .card-pass-template-body {
    @apply flex justify-between;
  }
}
</style>
