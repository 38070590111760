<template>
  <Container>
    <Section :collapsing="false"
      ><Heading>Pass Templates</Heading><span class="text-sm">{{ passTemplates.length }} items</span></Section
    >
    <Section>
      <div class="list-of-templates">
        <CardPassTemplate v-for="t in passTemplates" class="list-item-template" :template="t" :totalPasses="0" />
        <div class="flex items-center justify-center list-item-template">
          <Button class="my-4" size="tiny"><FaIcon icon="folder-plus" class="mr-2"></FaIcon>New Template</Button>
        </div>
      </div>
    </Section>
  </Container>
</template>

<script lang="ts" setup>
import Container from "@src/components/Container.vue";
import Heading from "@src/components/Heading.vue";
import Section from "@src/components/Section.vue";
import Button from "@src/components/Button.vue";

import CardPassTemplate from "./CardPassTemplate.vue";
</script>

<script lang="ts">
import { defineComponent } from "vue";
import { passesActions, passesComputed } from "@src/store/modules/passes/helper";

export default defineComponent({
  mounted() {
    this.doGetPassTemplates();
  },
  computed: {
    ...passesComputed,
  },
  methods: {
    ...passesActions,
  },
});
</script>

<style lang="postcss" scoped>
.list-of-templates {
  @apply flex flex-row flex-wrap gap-4;

  & .list-item-template {
    @apply w-full sm:w-[calc(25%-0.75rem)];
  }
}
</style>
