<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { accountActions, accountComputed } from "@src/store/modules/account/helper";
import { appComputed, appMutations } from "@src/store/modules/app/helper";

export default defineComponent({
  name: "MyRoot",
  components: {},
  computed: {
    ...appComputed,
    ...accountComputed,
  },
  watch: {
    errors(stateErrors) {
      Object.keys(stateErrors).forEach((errKey) => {
        // @ts-ignore
        // this.$toast.error(this.$t(err.${errors[errKey]);
        var self = this;
        // @ts-ignore
        this.$toast.error(this.$t(stateErrors[errKey]), {
          onClose: function () {
            self.setDismissError(errKey);
          },
        });
      });
    },
  },
  methods: {
    ...appMutations,
    ...accountActions,
  },
});
</script>

<style></style>
