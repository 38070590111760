<template>
  <component :is="tag" :class="['section', classes]">
    <div :class="['section-body', classBody]">
      <slot></slot>
    </div>
  </component>
</template>

<script lang="ts">
import { defineComponent } from "vue";

enum Sizes {
  MD = "md",
  LG = "lg",
  XL = "xl",
}

export default defineComponent({
  name: "MySection",
  props: {
    collapsing: {
      type: Boolean,
      default: true,
    },
    noBottom: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: "section",
    },
    size: {
      type: String,
      default: Sizes.MD,
    },
    classBody: {
      type: [Object, Array, String],
      default: () => ({}),
    },
  },
  data() {
    return {
      classes: {
        "no-collapsing": this.collapsing == false,
        "no-bottom": this.noBottom == true,
        md: this.size == Sizes.MD,
        lg: this.size == Sizes.LG,
        xl: this.size == Sizes.XL,
      },
    };
  },
});
</script>

<style lang="postcss" scoped>
.section {
  & .section-body {
    @apply my-4;
  }

  &.md {
    & .section-body {
      @apply my-4 sm:my-8;
    }
  }

  &.lg {
    & .section-body {
      @apply my-8 sm:my-16;
    }
  }

  &.no-collapsing {
    @apply flex flex-col;
  }

  &.no-bottom {
    & .section-body {
      @apply mb-0;
    }
  }
}
</style>
