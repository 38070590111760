import { createStore } from "vuex";

import { app } from "./modules/app";
import { passes } from "./modules/passes";
import { account } from "./modules/account";

export const store = createStore({
  modules: {
    app,
    passes,
    account,
  },
});
