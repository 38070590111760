<template>
  <img class="logo" :src="img" alt="init-company-logo" @click="() => onClick" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MyLogo",
  props: {
    onClick: {
      type: Function,
      default: () => () => {
        // todo
      },
    },
    img: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="postcss" scoped>
.logo {
  @apply inline-block w-12;
}
</style>
