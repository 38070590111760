import { createNamespacedHelpers } from "vuex";
import { MODULE_NAME, Mutations } from "./index";

const { mapState, mapGetters, mapMutations } = createNamespacedHelpers(MODULE_NAME);

export const appComputed = {
  ...mapState({
  }),
  ...mapGetters({ 
    appIsLoading: "isLoading", 
    errors: "errors" 
  }),
};

export const appMutations = {
  ...mapMutations([
    Mutations.SET_DISMISS_ERROR
  ]),
};
