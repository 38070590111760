import { createNamespacedHelpers } from "vuex";
import { MODULE_NAME, Actions, Mutations } from "./index";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(MODULE_NAME);

export const accountComputed = {
  ...mapState({
    token: "token",
    user: "user",
  }),
  ...mapGetters({
    accountIsLogged: "isLogged",
  }),
};

export const accountMutation = {
  ...mapMutations([Mutations.SET_TOKEN]),
};

export const accountActions = {
  ...mapActions([Actions.DO_LOGOUT, Actions.DO_PASSWORD_LOGIN, Actions.DO_REGISTER]),
};
