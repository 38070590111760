const messages = {
  buttonAction: {
    login: "Đăng nhập",
    register: "Đăng ký",
    logout: "Đăng xuất",
    console: "Console",
  },
  sideBar: {
    title: "Menu",
  },
  home: {
    secIntro: {
      logan: "Phát triển quan hệ  khách hàng với ứng dụng Easy Pass",
      content:
        "Giải pháp phân phát thẻ điện tử đến mọi khách hàng và quản lý thông tin thẻ bằng nền tảng web của Easy Pass hiệu quả và dễ sử dụng với chi phí tiết kiệm nhất",
      btnDiscovery: "Khám phá ngay",
    },
    secReason: {
      title1: "Vì sao sử dụng Easy Pass?",
      content1:
        "Bạn là chủ doanh nghiệp? Bạn là người quản lý? Bạn là người tổ chức sự kiện? Bạn đang phân phối thẻ thành viên đến khách hàng của mình? Hay bạn đang muốn gửi thông báo chương trình khuyến mãi đến các khách hàng thành viên của mình. Tất cả những điều bạn cần đều có trong Easy Pass",
      title2: "Ứng dụng Wallet Passes có gì?",
      content2:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus.",
      factor1: {
        title: "Tiết kiệm",
        content:
          "Không tốn chi phí triển khai  ban đầu, với chi phí rất nhỏ hàng tháng, bạn đã có thể tiếp cận khách hàng của mình thật dễ dàng",
      },
      factor2: {
        title: "Đơn giản",
        content:
          "Chỉ cần nhập các thông tin cơ bản là bạn có thể tải thẻ điện tử của chúng tôi về điện thoại hoặc phát thẻ đến một người nào đó",
      },
      factor3: {
        title: "Đa dạng hóa các loại thẻ",
        content:
          "Bạn có thể tạo sẵn các mẫu thẻ (Pass Template) với các giao diện và thông tin khác nhau tùy vào nhu cầu sử dụng: thẻ thành viên, voucher điện tử...",
      },
      factor4: {
        title: "Cá nhân hóa cho từng đối tượng",
        content: "Cá nhân hóa thông tin của từng khách hàng khi phân phát một mẫu thẻ điện tử",
      },
      factor5: {
        title: "Ứng dụng có sẵn",
        content:
          "Easy Pass phân phối thẻ điện tử thông qua ứng dụng có sẵn hoàn toàn miễn phí của iOS (Apple Wallet) hoặc ứng dụng do Easy Pass phát triển.",
      },
      factor6: {
        title: "Đội ngũ hỗ trợ nhiều kinh nghiệm",
        content:
          "Bạn đang phân vân làm sao áp dụng được với mô hình kinh doanh của của bạn, Liên hệ với chúng tôi ngay!!",
      },
    },
    secGuide: {
      title: "Bắt đầu chỉ với các bước cơ bản",
      step1: {
        title: "Đăng kí / Đăng nhập tài khoản Easy Pass",
      },
      step2: {
        title: "Đăng nhập thành công, vào trang Quản lí chọn mẫu thẻ bạn muốn",
      },
      step3: {
        title: "Nhập thông tin theo mẫu và bấm tải thẻ. (Giao diện Desktop/Ipad sẽ xuất hiện thêm mã QR Code)",
      },
      step4: {
        title: "Tải ứng dụng Easy Pass tại CH Play hoặc App Store",
      },
      step5: {
        title: "Mở ứng dụng Easy Pass, bấm quét QR Code để lấy thẻ của bạn.",
      },
    },
    sectionUsecases: {
      title: "Dịch vụ thẻ Easy Pass phù hợp mọi nhu cầu",
    },
    secPasses: {
      title: 'Tải "Thẻ Khách Hàng" nhận ngay ưu đãi Easy Pass',
      btnDownload: "Tạo thẻ",
      cardForm: {
        labelType: "Loại thẻ",
        type: "Chọn loại",
        titleScan: "Mở ứng dụng Easy Pass để quét mã này nhé",
        labelFullName: "Họ và tên",
        labelBirth: "Ngày sinh",
        labelCheckInDate: "Ngày nhận phòng",
        labelEmail: "Email (nên có)",
        labelPhone: "Số điện thoại (nên có)",
        labelOTP: "Mã OTP",
      },
    },
  },
  authen: {
    login: {
      title: "Đăng nhập",
      description: "",
      btnLogin: "Đăng nhập",
      forgotPass: "Quên mật khẩu",
      register: "Tạo tài khoản",
      phUsername: "Tên đăng nhập",
      phPass: "Mật khẩu",
    },
    register: {
      title: "Đăng ký",
      description: "",
      btnRegister: "Đăng ký",
      alreadyAcc: "Có tài khoản rồi",
      backLogin: "Quay về",
      phUsername: "Tên đăng nhập",
      phMobile: "Số điện thoại",
      phEmail: "Email",
    },
    forgot: {
      title: "Quên mật khẩu",
      description: "",
    },
  },
  covid: {
    title: "Cập nhật Thẻ Covid",
    btnUpdate: "Cập nhật",
  },
  err: {
    userNameMissing: "Vui lòng nhập tên đăng nhập",
    userNameInvalid: "Tên đăng nhập ít nhất 5 ký tự",
    fullNameMissing: "Vui lòng nhập họ tên",
    birthMissing: "Vui lòng nhập ngày sinh",
    checkInDateMissing: "Vui lòng nhập ngày nhận phòng",
    phoneMissing: "Vui lòng nhập số điện thoại",
    phoneInvalid: "Số điện thoại không hợp lệ",
    emailMissing: "Vui lòng nhập email",
    emailInvalid: "Email không hợp lệ",
    passwordMissing: "Vui lòng nhập mật khẩu",
    confirmPasswordMissing: "Vui lòng xác nhận mật khẩu",
    otpMissing: "Vui lòng nhập mã OTP",
    confirmPasswordNotEqual: "Xác nhận mật khẩu không khớp",
    pageNotFound: "404 - Không tìm thấy trang",
    loginFail: "Vui lòng kiểm tra lại thông tin đăng nhập",
    registerFail: "Đăng ký không thành công",
    PASSWORD_WEAK: "Mật khẩu ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường và chữ số",
    SYSTEM_FAIL: "Lỗi hệ thống",
  },
  success: {
    loginSuccess: "Đăng nhập thành công",
    registerSuccess: "Đăng ký thành công",
  },
};

export default messages;
