<template>
  <div :class="['guide-item', classes]">
    <div class="guide-item-img">
      <img class="w-60" :src="src" />
    </div>
    <Box class="guide-item-body" size="small">
      <Heading class="guide-item-label">{{ label }}</Heading>
      <p class="guide-item-desc">{{ description }}</p>
    </Box>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Box from "@src/components/Box.vue";
import Heading from "@src/components/Heading.vue";

enum Kinds {
  IMG_RIGHT = "img-right",
  IMG_LEFT = "img-left",
}

export default defineComponent({
  components: { Box, Heading },
  props: {
    kind: {
      type: String,
      default: Kinds.IMG_LEFT,
    },
    src: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      classes: {
        "img-right": this.kind == Kinds.IMG_RIGHT,
        "img-left": this.kind == Kinds.IMG_LEFT,
      },
    };
  },
});
</script>

<style lang="postcss" scoped>
.guide-item {
  @apply flex flex-col gap-x-16 items-center sm:flex-row;
  width: fit-content;

  & .guide-item-img {
    @apply mb-4 sm:order-none sm:mb-0 border-b border-dashed border-neutral-400;

    & img {
      @apply mx-auto sm:h-auto;

      max-width: 10rem;
      @media (min-width: 640px) {
        max-width: none;
      }
    }
  }
  & .guide-item-label {
    @apply mb-2 text-primary;
    @apply text-center sm:text-left;
  }
  & .guide-item-desc {
    @apply text-center sm:text-left;
  }

  /* Kind: IMG_RIGHT */
  &.img-right {
    & .guide-item-img {
      @apply sm:order-last;
    }
  }
}
</style>
