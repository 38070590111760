<template>
  <div class="section-login">
    <div class="login-body">
      <Container>
        <div class="flex justify-center">
          <div class="w-full md:w-1/2 lg:w-1/3">
            <div class="p-4 login-wrap">
              <div class="flex items-center justify-center icon">
                <router-link :to="{ name: 'p-home' }">
                  <Logo :img="initLogo" class="cursor-pointer" />
                </router-link>
              </div>
              <div class="mb-4 text-center">
                <Heading>{{ $t("authen.login.title") }}</Heading>
              </div>
              <div class="login-form">
                <InputField
                  class="pt-4"
                  type="text"
                  :preIcon="'user'"
                  :value="username"
                  :placeholder="$t('authen.login.phUsername')"
                  :name="'username'"
                  :on-change="(val) => (username = val)"
                  :required="true"
                  :errMsg="errCheck.username"
                />
                <InputField
                  class="py-4"
                  :type="'password'"
                  :preIcon="'lock'"
                  placeholder="*********"
                  :value="password"
                  :isPass="true"
                  :name="'password'"
                  :on-change="(val) => (password = val)"
                  :required="true"
                  :errMsg="errCheck.password"
                />
                <div class="form-group">
                  <Button primary class="w-full" @click="doLogin">{{ $t("authen.login.btnLogin") }}</Button>
                </div>
                <!-- <div class="form-group">
                  <div class="text-right forgot" @click="doForgotPass">{{ $t("authen.login.forgotPass") }}</div>
                </div> -->
                <div class="text-center register text-primary" @click="forwardRegister">
                  {{ $t("authen.login.register") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// import { router } from "@src/router";
import InputField from "@src/components/InputField.vue";
import Container from "@src/components/Container.vue";
import Button from "@src/components/Button.vue";
import Heading from "@src/components/Heading.vue";
import Logo from "@src/components/Logo.vue";
import InitLogo from "@src/assets/images/logo.png";
import { accountActions, accountComputed } from "@src/store/modules/account/helper";
import { appComputed } from "@src/store/modules/app/helper";

const images = {
  InitLogo: InitLogo,
};

export default defineComponent({
  name: "PLogin",
  components: {
    Container,
    Heading,
    Logo,
    Button,
    InputField,
  },
  data() {
    return {
      username: "",
      password: "",
      errCheck: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    ...appComputed,
    ...accountComputed,
    initLogo() {
      return images.InitLogo;
    },
  },
  methods: {
    ...accountActions,
    forwardRegister() {
      this.$router.push({ name: "p-register" });
    },
    validateForm() {
      let valid = true;

      if (!this.username) {
        this.errCheck.username = this.$t("err.userNameMissing");
        valid = false;
      } else {
        this.errCheck.username = "";
      }

      if (!this.password) {
        this.errCheck.password = this.$t("err.passwordMissing");
        valid = false;
      } else {
        this.errCheck.password = "";
      }

      return valid;
    },
    doLogin() {
      if (this.validateForm()) {
        let payload = {
          username: this.username,
          password: this.password,
        };
        let queryURLs = this.$router.currentRoute.value.query;
        let redirect = "";

        if (Object.keys(queryURLs).length > 0) {
          redirect += queryURLs.redirect != undefined ? queryURLs.redirect : "";
        }

        this.doPasswordLogin(payload).then((data) => {
          if (data) {
            if (redirect != "") {
              this.$router.push({ path: `${redirect}` });
            } else {
              this.$router.push({ name: "p-templates" });
            }
          }
        });
      }
    },
    // doForgotPass() {
    //   console.log("TODO to forgot password");
    // },
  },
});
</script>

<style lang="postcss" scoped>
.section-login {
  @apply h-screen;
  & .login-body {
    @apply h-full items-center flex flex-row flex-wrap;
    & .login-wrap {
      border-radius: 10px;
      box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
      & .icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin: 0 auto;
      }
      & .form-group {
        margin-bottom: 1rem;
        & .form-control {
          @apply w-full rounded-md;
          color: #000000;
          height: 50px;
          border: 1px solid #ced6e0;
          padding: 5px 15px;

          &:focus {
            @apply border-accent-cyan shadow-light;
          }
        }
        & .forgot {
          @apply text-primary cursor-pointer text-size-2;
        }
      }
      & .register {
        @apply mt-10 cursor-pointer;
      }
    }
  }
}
</style>
