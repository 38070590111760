<template>
  <!-- SECTION HERO -->
  <Section tag="section" size="lg" :collapsing="false" :no-bottom="true" class="hero">
    <Container class="hero-body">
      <div class="hero-item hero-left">
        <Heading tag="h1" size="lg">{{ $t("home.secIntro.logan") }}</Heading>
        <p>{{ $t("home.secIntro.content") }}</p>
        <a href="#create-pass">
          <Button>{{ $t("home.secIntro.btnDiscovery") }}</Button>
        </a>
      </div>
      <div class="hero-item hero-right">
        <div class="hero-img">
          <img src="/hero.png" />
        </div>
      </div>
    </Container>
  </Section>

  <!-- SECTION INTRODUCTION -->

  <Section size="lg" class="introduction">
    <Container>
      <Box class="introduction-header">
        <Heading tag="h2" size="lg">{{ $t("home.secReason.title1") }}</Heading>
        <p>{{ $t("home.secReason.content1") }}</p>
      </Box>
      <div class="introduction-body">
        <div class="mb-8 introduction-items sm:mb-0">
          <IntroItem
            icon="piggy-bank"
            :label="$t('home.secReason.factor1.title')"
            :description="$t('home.secReason.factor1.content')"
          />
          <IntroItem
            icon="thumbs-up"
            :label="$t('home.secReason.factor2.title')"
            :description="$t('home.secReason.factor2.content')"
          />
          <IntroItem
            icon="cubes"
            :label="$t('home.secReason.factor3.title')"
            :description="$t('home.secReason.factor3.content')"
          />
        </div>
        <div class="introduction-img">
          <img src="/introduction.png" />
          <div class="hidden img-bg sm:block">
            <div class="squared aspect-w-1 aspect-h-1"></div>
          </div>
        </div>
        <div class="introduction-items">
          <IntroItem
            kind="icon-left"
            icon="universal-access"
            :label="$t('home.secReason.factor4.title')"
            :description="$t('home.secReason.factor4.content')"
          />
          <IntroItem
            kind="icon-left"
            icon="check-circle"
            :label="$t('home.secReason.factor5.title')"
            :description="$t('home.secReason.factor5.content')"
          />
          <IntroItem
            kind="icon-left"
            icon="users"
            :label="$t('home.secReason.factor6.title')"
            :description="$t('home.secReason.factor6.content')"
          />
        </div>
      </div>
    </Container>
  </Section>

  <!-- SECTION GUIDE -->
  <div class="flow-root" />
  <Section size="lg" class="guide">
    <Container>
      <div class="guide-header">
        <Heading tag="h2" size="lg">{{ $t("home.sectionUsecases.title") }}</Heading>
      </div>
      <div class="guide-items">
        <GuideItem
          src="/usecases/assistance_card.webp"
          kind="img-left"
          label="Thẻ bảo hiểm"
          description="Thẻ hỗ trợ kỹ thuật số mang lại sự thoải mái cho chủ hợp đồng, mọi thông tin đều có thể dễ dàng truy cập trên định dạng đơn giản, dễ hiểu của thẻ kỹ thuật số."
        />
        <GuideItem
          src="/usecases/loyalty_card.webp"
          kind="img-right"
          label="Thẻ thành viên cao cấp"
          description="Cần phải có thẻ tích điểm ở dạng kỹ thuật số, nếu không chúng rất dễ bị quên hoặc mất. Tuyệt vời cho các cửa hàng, chuỗi bán lẻ, nhà hàng, dịch vụ, mạng lưới trạm xăng và tất cả các công ty muốn xây dựng lòng trung thành của khách hàng."
        />
        <GuideItem
          src="/usecases/voucher_card.webp"
          kind="img-left"
          label="Thẻ quà tặng & khuyến mãi"
          description="Chủ thẻ được thông báo về số dư điểm và phần thưởng của họ trong thời gian thực và nhờ các tính năng lân cận, họ có thể được nhắc nhở về ưu đãi của bạn vào đúng thời điểm họ ở gần cửa hàng của bạn. Thông báo đẩy được gửi trên màn hình khóa đang mở ra một kênh giao tiếp hoàn toàn mới cho hành trình của khách hàng của bạn."
        />
        <GuideItem
          src="/usecases/event_ticket.webp"
          kind="img-right"
          label="Vé sự kiện & dịch vụ"
          description="Vé kỹ thuật số cập nhật dễ dàng và ngay lập tức, giao tiếp qua thông báo (tin tức về một sự kiện, thông tin tổ chức, các trường hợp bất ngờ như thay đổi ngày hoặc hủy), xác nhận vé nhanh hơn, cải thiện bảo mật nhờ NFC."
        />
        <GuideItem
          src="/usecases/boarding_pass.webp"
          kind="img-left"
          label="Thậm chí... Vé máy bay"
          description="Có tất cả các chi tiết đặt vé ở một nơi, được cá nhân hóa với tên của bạn, dễ dàng truy cập từ màn hình khóa nhờ tọa độ GPS và nhận thông báo đẩy khi cửa khẩu hoặc giờ lên máy bay của bạn thay đổi - tất cả điều này chứng tỏ sự tiện lợi của thẻ kỹ thuật số."
        />
        <Heading class="text-center" tag="h3" size="md"
          >Không tìm thấy loại thẻ bạn muốn triển khai?<br />Liên hệ chúng tôi để tư vấn miễn phí
          <a class="text-primary" href="mailto:support@init.vn">support@init.vn</a><br />hoặc mobile
          <a class="text-primary" href="tel:+84982574806">09.82.574.806</a>
        </Heading>
      </div>
    </Container>
  </Section>

  <Section size="lg" :collapsing="false" class="customer-pass bg-accent-yellow-100">
    <Container>
      <Box class="customer-pass-header">
        <Heading tag="h2" size="lg">{{ $t("home.secPasses.title") }}</Heading>
      </Box>
      <a id="create-pass-form" name="create-pass" />
      <img class="w-[1px]" src="/add_to_wallet_btn.png" />
      <div class="customer-pass-body">
        <div class="pass-review" v-if="!downloadPkpassLink">
          <img class="w-52 sm:w-72" src="/hero.png" />
        </div>
        <div class="pass-creation-form">
          <template v-if="!downloadPkpassLink">
            <InputField
              type="text"
              :label="$t('home.secPasses.cardForm.labelEmail')"
              placeholder="vietnam900@gmail.com"
              :value="email"
              name="email"
              :on-change="(val: string) => (email = val)"
              :errMsg="errCheck.email"
            />
            <InputField
              type="text"
              :label="$t('home.secPasses.cardForm.labelPhone')"
              placeholder="09616xxyyz"
              :value="phoneNumber"
              name="phoneNumber"
              :on-change="(val: string) => (phoneNumber = val)"
              :errMsg="errCheck.phoneNumber"
            />
            <Button primary class="w-full" :on-click="downloadPass">
              {{ $t("home.secPasses.btnDownload") }}
            </Button>
            <div>
              Chúng tôi cam kết thông tin được cung cấp chỉ được sử dụng để hỗ trợ khách hàng của Easy Pass tốt hơn.
            </div>
            <div>
              Easy Pass tuyệt đối tôn trọng sự riêng tư và dữ liệu cá nhân của khách hàng theo tiêu chuẩn cao nhất.
            </div>
          </template>
          <template v-if="downloadPkpassLink">
            <img v-if="!isMobile" class="mx-auto w-52" src="/add_to_wallet_btn.png" />
            <div class="pkpass-qrcode" v-if="!isMobile">
              <qrcode-vue :value="downloadPkpassLink" :size="180" level="H" />
              <div class="mt-2">Quét mã QR bằng <br />điện thoại để lấy Thẻ</div>
            </div>
            <div class="block w-[fit-content] mx-auto">
              <img @click="redirectDownload" v-if="isMobile" class="mx-auto w-52" src="/add_to_wallet_btn.png" />
            </div>
            <div class="block w-[fit-content] mx-auto">
              <Button size="tiny" kind="outlined" :on-click="() => (downloadPkpassLink = '')"> Tạo lại </Button>
              <a v-if="isMobile" href="shoebox://"><Button size="tiny" class="ml-2"> 💳 Mở ví </Button></a>
            </div>
            <div>
              Khi thêm thẻ được lưu vào <span class="font-bold">ứng dụng Wallet</span> được Apple cài sẵn trong máy
              iPhone.
            </div>
            <div>
              Hiện tại dịch vụ chỉ hỗ trợ iOS trên iPhone, chúng tôi đang hoàn thiện ứng dụng Android, cố gắng mang trải
              nghiệm tương tự đến Android trong thời gian sớm nhất. Xin chân thành cảm ơn quý khách!
            </div>
          </template>
        </div>
      </div>
    </Container>
  </Section>

  <Section tag="section" size="lg" :collapsing="false" class="bg-black">
    <Container class="flex justify-center items-center space-x-4"
      ><img class="w-8 h-8" src="/logo.png" alt="" />
      <div>
        Easy Pass là một sản phẩm của công ty <a class="font-bold" href="https://init.vn">INIT Technology Solutions</a>.
      </div>
    </Container>
  </Section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Container from "@src/components/Container.vue";
import Section from "@src/components/Section.vue";
import Box from "@src/components/Box.vue";
import Button from "@src/components/Button.vue";
import Heading from "@src/components/Heading.vue";
import InputField from "@src/components/InputField.vue";

import IntroItem from "./IntroItem.vue";
import GuideItem from "./GuideItem.vue";
import QrcodeVue from "qrcode.vue";

import { isEmailValid, isPhoneValid } from "@src/lib/validate";
import { passesActions } from "@src/store/modules/passes/helper";
import { appComputed } from "@src/store/modules/app/helper";
import { http } from "@src/http";

export default defineComponent({
  name: "PHome",
  components: {
    Container,
    Section,
    Button,
    Heading,
    Box,
    InputField,
    // chunks
    IntroItem,
    GuideItem,
    QrcodeVue,
  },
  props: {
    msg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      phoneNumber: "",
      email: "",
      downloadPkpassLink: "",
      isMobile: false,
      valueQRCode: "",
      errCheck: {
        email: "",
        phoneNumber: "",
      },
    };
  },
  mounted() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (this.$route.hash) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 500);
    }
  },
  computed: {
    ...appComputed,
  },
  methods: {
    ...passesActions,
    validateForm() {
      if (this.email && !isEmailValid(this.email)) {
        this.errCheck.email = this.$t("err.emailInvalid");
        return false;
      }

      if (this.phoneNumber && !isPhoneValid(this.phoneNumber)) {
        this.errCheck.phoneNumber = this.$t("err.phoneMissing");
        return false;
      }

      return true;
    },
    downloadPass() {
      if (!this.validateForm()) return;

      let payload = {
        passTemplateID: 2,
        paramValues: {
          email: this.email,
          phone: this.phoneNumber,
          referral: this.$route.query.referral || "",
          message: "",
        },
      };

      this.doCreatePass(payload).then((serial) => {
        this.downloadPkpassLink = `${http.baseURL}/pkpass/${serial}`;
        if (this.isMobile) {
          this.redirectDownload();
        }
      });
    },
    redirectDownload() {
      window.location.href = this.downloadPkpassLink;
    },
  },
});
</script>

<style lang="postcss" scoped>
.hero {
  @apply bg-secondary-100;

  & .hero-body {
    @apply flex flex-wrap sm:pt-16;
    & .hero-item {
      @apply w-full md:w-1/2;
    }

    & .hero-left {
      @apply flex flex-wrap content-end items-end pb-16 space-y-4 md:pr-12 md:pb-20 md:pt-16;
    }
    & .hero-right {
      @apply bg-accent-yellow-100;

      & .hero-img {
        @apply mx-auto -mt-0 md:-mt-32;
        max-width: 16rem;
        @media (min-width: 640px) {
          max-width: 30rem;
        }
      }
    }
  }
}

.introduction {
  & .introduction-header {
    @apply mx-auto text-center space-y-4 sm:mb-12;
  }

  & .introduction-body {
    @apply flex flex-row flex-wrap gap-x-0 items-end sm:gap-x-8;

    & .introduction-img,
    & .introduction-items {
      @apply w-full space-y-8 sm:flex-1 sm:w-auto sm:space-y-12;
    }

    & .introduction-img {
      @apply relative;
      @apply order-first space-y-0 sm:order-none;

      & .img-bg {
        @apply absolute -z-1 bottom-0 w-full max-w-sm;
      }

      & img {
        @apply sm:max-h-[none] mx-auto max-h-64;
      }
    }
  }
}

.guide {
  & .guide-header {
    @apply mb-8 text-center sm:mb-28;
  }

  & .guide-items {
    @apply flex flex-col space-y-8 items-center sm:space-y-28;
  }
}

.customer-pass {
  & .customer-pass-header {
    @apply mb-8 mx-auto text-center space-y-4 sm:mb-12;
  }

  & .customer-pass-body {
    @apply flex flex-col sm:flex-row justify-center items-center gap-8;

    & .pass-creation-form {
      @apply w-full sm:w-[30rem] space-y-4;

      & .pkpass-qrcode {
        @apply bg-white p-4 rounded-lg shadow-light text-center mx-auto;
        width: fit-content;
      }
    }
  }
}
</style>
