<template>
  <div class="section-register">
    <div class="register-body">
      <Container>
        <div class="flex justify-center">
          <div class="w-full md:w-1/2 lg:w-1/3">
            <div class="p-4 register-wrap">
              <div class="flex items-center justify-center icon">
                <router-link :to="{ name: 'p-home' }">
                  <Logo :img="initLogo" class="cursor-pointer" />
                </router-link>
              </div>
              <div class="mb-4 text-center">
                <Heading>{{ $t("authen.register.title") }}</Heading>
              </div>
              <div class="register-form">
                <InputField
                  class="pt-4"
                  type="text"
                  :preIcon="'user'"
                  :value="username"
                  :placeholder="$t('authen.register.phUsername')"
                  :name="'username'"
                  :on-change="(val) => (username = val)"
                  :required="true"
                  :errMsg="errCheck.username"
                />
                <InputField
                  class="pt-4"
                  type="text"
                  :preIcon="'mobile-alt'"
                  :value="mobile"
                  :placeholder="$t('authen.register.phMobile')"
                  :name="'mobile'"
                  :on-change="(val) => (mobile = val)"
                  :required="true"
                  :errMsg="errCheck.mobile"
                />
                <InputField
                  class="pt-4"
                  type="text"
                  :preIcon="'envelope'"
                  :value="email"
                  :placeholder="$t('authen.register.phEmail')"
                  :name="'email'"
                  :on-change="(val) => (email = val)"
                  :required="true"
                  :errMsg="errCheck.email"
                />
                <InputField
                  class="pt-4"
                  :type="'password'"
                  placeholder="*********"
                  :value="password"
                  :isPass="true"
                  :preIcon="'lock'"
                  :name="'password'"
                  :on-change="(val) => (password = val)"
                  :required="true"
                  :errMsg="errCheck.password"
                />
                <InputField
                  class="py-4"
                  :type="'password'"
                  placeholder="*********"
                  :value="confirmPassword"
                  :isPass="true"
                  :preIcon="'lock'"
                  :name="'confirmPassword'"
                  :on-change="(val) => (confirmPassword = val)"
                  :required="true"
                  :errMsg="errCheck.confirmPassword"
                />
                <div class="form-group">
                  <Button primary class="w-full" @click="register">{{ $t("authen.register.btnRegister") }}</Button>
                </div>
                <div class="text-center back text-primary" @click="backLogin">
                  {{ $t("authen.register.backLogin") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import InputField from "@src/components/InputField.vue";
import Container from "@src/components/Container.vue";
import Button from "@src/components/Button.vue";
import Heading from "@src/components/Heading.vue";
import Logo from "@src/components/Logo.vue";
import InitLogo from "@src/assets/images/logo.png";
import { appComputed } from "@src/store/modules/app/helper";
import { accountActions, accountComputed } from "@src/store/modules/account/helper";
import { isEmailValid, isMediumPassword, isUsernameValid, isPhoneValid } from "@src/lib/validate";

const images = {
  InitLogo: InitLogo,
};

export default defineComponent({
  name: "PRegister",
  components: {
    Container,
    Heading,
    Logo,
    Button,
    InputField,
  },
  data() {
    return {
      username: "",
      mobile: "",
      email: "",
      password: "",
      confirmPassword: "",
      errCheck: {
        username: "",
        mobile: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  computed: {
    ...appComputed,
    ...accountComputed,
    initLogo() {
      return images.InitLogo;
    },
  },
  methods: {
    ...accountActions,
    backLogin() {
      this.$router.push({ name: "p-login" });
    },
    validateForm() {
      let valid = true;
      if (!this.username) {
        this.errCheck.username = this.$t("err.userNameMissing");
        valid = false;
      } else if (!isUsernameValid(this.username)) {
        this.errCheck.username = this.$t("err.userNameInvalid");
        valid = false;
      } else {
        this.errCheck.username = "";
      }

      if (!this.mobile) {
        this.errCheck.mobile = this.$t("err.phoneMissing");
        valid = false;
      } else if (!isPhoneValid(this.mobile)) {
        this.errCheck.mobile = this.$t("err.phoneInvalid");
        valid = false;
      } else {
        this.errCheck.mobile = "";
      }

      if (!isEmailValid(this.email)) {
        this.errCheck.email = this.$t("err.emailInvalid");
        valid = false;
      } else {
        this.errCheck.email = "";
      }

      if (!this.password) {
        this.errCheck.password = this.$t("err.passwordMissing");
        valid = false;
      } else if (!isMediumPassword(this.password)) {
        this.errCheck.password = this.$t("err.PASSWORD_WEAK");
        valid = false;
      } else {
        this.errCheck.password = "";
      }

      if (!this.confirmPassword) {
        this.errCheck.confirmPassword = this.$t("err.confirmPasswordMissing");
        valid = false;
      } else if (!!this.confirmPassword && this.password !== this.confirmPassword) {
        this.errCheck.confirmPassword = this.$t("err.confirmPasswordNotEqual");
        valid = false;
      } else {
        this.errCheck.confirmPassword = "";
      }

      return valid;
    },
    register() {
      if (this.validateForm()) {
        let payload = {
          username: this.username,
          email: this.email,
          mobile: this.mobile,
          password: this.password,
        };
        this.doRegister(payload).then((data) => {
          if (data) {
            this.$router.push({ name: "p-login" });
          }
        });
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
.section-register {
  @apply h-screen;
  & .register-body {
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & .register-wrap {
      border-radius: 10px;
      box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
      & .icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin: 0 auto;
      }
      & .form-group {
        margin-bottom: 1rem;
        & .form-control {
          @apply w-full rounded-md;
          color: #000000;
          height: 50px;
          border: 1px solid #ced6e0;
          padding: 5px 15px;

          &:focus {
            @apply border-accent-cyan shadow-light;
          }
        }
      }
      & .back {
        @apply mt-10 cursor-pointer;
      }
    }
  }
}
</style>
