<template>
  <Container>
    <Section>
      <Heading class="block sm:hidden">View = Mobile</Heading>
      <Heading class="hidden sm:block">View >= Tablet</Heading>
    </Section>
    <Section>
      <Heading class="mb-4 underline">Button Component</Heading>
      <Button class="mr-4">Primary Normal</Button>
      <Button size="small">Primary Small</Button>
    </Section>
    <Section>
      <Heading class="underline">Heading Component</Heading>
      <Heading tag="h1" size="xl">Heading XL</Heading>
      <!-- default tag is h3-->
      <Heading size="lg">Heading LG</Heading>
      <!-- default size is MD -->
      <Heading>Heading MD</Heading>
    </Section>
  </Container>
  <Section>
    <Container>
      <Heading class="underline">Section Component</Heading>
    </Container>
    <Section class="bg-neutral-200">
      <div class="border border-accent-yellow">
        <Container>
          <Heading>Section Collapsing = True</Heading>
          Body margin outside
        </Container>
      </div>
    </Section>
    <Section :collapsing="false" class="bg-neutral-200 border border-accent-yellow">
      <Container>
        <Heading>Section Collapsing = False</Heading>
        Body margin inside
      </Container>
    </Section>
  </Section>
  <Section>
    <Container>
      <Heading class="underline">Input Component</Heading>
    </Container>
    <Container>
      <InputField
        class="mb-5 md:w-1/2"
        type="text"
        :preIcon="'users'"
        :suffIcon="'rocket'"
        :label="'Input1'"
        placeholder="Input 1"
        :value="input1"
        :name="'input1'"
        :required="true"
      />
      <InputField 
        class="mb-5 md:w-1/2"
        type="text"
        :label="'Input2'"
        :value="input2" 
        placeholder="Input 2">
        <template v-slot:pre-icon>
          <div class="w-6">
            <img :src="imgSrc" />
          </div>
        </template>
      </InputField>
    </Container>
  </Section>
</template>

<script lang="ts">
import Container from "@src/components/Container.vue";
import Section from "@src/components/Section.vue";
import Button from "@src/components/Button.vue";
import Heading from "@src/components/Heading.vue";
import InputField from "@src/components/InputField.vue";
import InitLogo from "@src/assets/images/logo.png";

const images = {
  InitLogo: InitLogo,
};

export default {
  name: "PComponents",
  components: {
    Container,
    Section,
    Button,
    Heading,
    InputField,
  },
  props: {},
  data() {
    return {
      input1: "",
      input2: "",
      imgSrc: images.InitLogo,
    }
  },
};
</script>

<style lang="postcss" scoped></style>
