<template>
  <div class="layout-app">
    <div class="layout-land">
      <MySidebar />
      <div class="layout-page">
        <MyHeader class="layout-top" />
        <div class="layout-body">
          <!-- {{ route.fullPath }} -->
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import MySidebar from "./Sidebar.vue";
import MyHeader from "./Header.vue";
</script>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  computed: {
    route() {
      return this.$route;
    },
  },
});
</script>

<style lang="postcss" scoped>
.layout-app {
  @apply flex flex-col min-h-screen max-h-screen;
  @apply bg-neutral-100 text-dark;

  & .layout-land {
    @apply flex flex-row flex-grow;

    & .layout-page {
      @apply w-full flex flex-col;

      & .layout-top {
        @apply flex items-center h-12 sm:h-16 bg-primary-700 shadow-nav;
      }

      & .layout-body {
        @apply h-[calc(100vh-3rem)] sm:h-[calc(100vh-4rem)] overflow-y-scroll;
      }
    }
  }
}
</style>
