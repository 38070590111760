import { http, newCtxConfig } from "@src/http";
import { Pass, PassTemplate } from "@src/models/passes";
import { AxiosResponse } from "axios";
import { Module } from "vuex";

export const state = {
  passTemplates: [] as PassTemplate[],
  passesOfTemplate: [] as Pass[],
};

export enum Mutations {
  SET_PASS_TEMPLATES = "setPassTemplates",
  SET_PASSES = "setPasses",
}

export enum Actions {
  DO_GET_PASS_TEMPLATES = "doGetPassTemplates",
  DO_GET_PASSES = "doGetPasses",
  DO_CREATE_PASS = "doCreatePass",
}

export const MODULE_NAME = "passes";

export const passes: Module<typeof state, unknown> = {
  namespaced: true,
  state: () => state,
  getters: {},
  actions: {
    [Actions.DO_GET_PASS_TEMPLATES]({ commit }) {
      return http.client
        .get(
          "/pass-templates",
          newCtxConfig({
            action: Actions.DO_GET_PASS_TEMPLATES,
          })
        )
        .then(async ({ data }) => {
          console.log(data.data);
          commit(Mutations.SET_PASS_TEMPLATES, data.data);
        });
    },

    [Actions.DO_CREATE_PASS](_, payload) {
      /**
       * payload structure
       * {
       *    "passTemplateID": "1",
       *    "paramVals": {
       *      "property1": "value",
       *      "property2": "value",
       *    },
       * }
       */
      return http.client
        .post(
          "/passes",
          payload,
          newCtxConfig({
            action: Actions.DO_CREATE_PASS,
          })
        )
        .then(
          (resp: AxiosResponse<Pass>) => {
            return resp.data.serial;
          },
          (err) => {
            console.debug("Create pass err:", err.response);
            return null;
          }
        );
    },

    [Actions.DO_GET_PASSES]({ commit }, { passTemplateID }) {
      return http.client
        .get(
          `/passes?passTemplateID=${passTemplateID}`,
          newCtxConfig({
            action: Actions.DO_GET_PASSES,
          })
        )
        .then(({ data }) => {
          console.log(data);
          commit(Mutations.SET_PASSES, data.data);
        });
    },
  },
  mutations: {
    // [mutations.SAVE_TOKEN](_, { token }) {
    //   localStorage.setItem(LocalToken, token);
    // },
    [Mutations.SET_PASSES](state, passes) {
      state.passesOfTemplate = passes;
    },
    [Mutations.SET_PASS_TEMPLATES](state, tmpls) {
      state.passTemplates = tmpls;
    },
  },
};
