<template>
  <MyContainer>
    <MySection :collapsing="false" :noBottom="true">
      <MyHeading>Passes</MyHeading>
      <span class="text-sm">{{ passesOfTemplate.length }} items</span>
    </MySection>
    <MySection>
      <div class="list-of-passes">
        <CardPass v-for="p in passesOfTemplate" :pass="p" class="list-item-pass" />
      </div>
    </MySection>
  </MyContainer>
</template>

<script lang="ts" setup>
import MyContainer from "@src/components/Container.vue";
import MyHeading from "@src/components/Heading.vue";
import MySection from "@src/components/Section.vue";

import CardPass from "./CardPass.vue";
</script>

<script lang="ts">
import { defineComponent } from "vue";
import { passesActions, passesComputed } from "@src/store/modules/passes/helper";
export default defineComponent({
  mounted() {
    this.doGetPasses({
      passTemplateID: this.$route.params["id"],
    });
  },
  methods: {
    ...passesActions,
  },
  computed: {
    ...passesComputed,
  },
});
</script>

<style lang="postcss" scoped>
.list-of-passes {
  @apply flex flex-row flex-wrap gap-4;
  & .list-item-pass {
    @apply w-full sm:w-[calc(50%-1.5rem)];
  }
}
.card {
  @apply bg-light shadow-light rounded overflow-hidden;

  & .card-header {
    @apply p-2 sm:p-4 pb-0 sm:pb-0 mb-2 sm:mb-4;
    @apply text-size-2 sm:text-size-3 font-bold cursor-pointer;
  }

  & .card-body {
    @apply px-2 sm:px-4 my-2 sm:my-4;
    @apply text-sm;
  }

  & .card-footer {
    & .card-button {
      @apply w-1/3;
      @apply border-neutral-200 rounded-none first:rounded-b last:rounded-b first:rounded-r-none last:rounded-l-none first:border-r-0 last:border-l-0;
      @apply border-b-0 first:border-l-0 last:border-r-0;

      & :deep(.label) {
        @apply font-semibold;
      }
    }
  }
}
</style>
