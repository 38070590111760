import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faUserCircle,
  faDoorOpen,
  faRocket,
  faTrash,
  faTools,
  faFolderPlus,
  faSwatchbook,
  faClock,
  faMobile,
  faSignOutAlt,
  faPiggyBank,
  faThumbsUp,
  faCubes,
  faUniversalAccess,
  faCheckCircle,
  faUsers,
  faEye,
  faEyeSlash,
  faEnvelope,
  faLock,
  faMobileAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBars,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faUserCircle,
  faSwatchbook,
  faDoorOpen,
  faRocket,
  faTrash,
  faTools,
  faClock,
  faFolderPlus,
  faMobile,
  faMobileAlt,
  faSignOutAlt,
  faPiggyBank,
  faThumbsUp,
  faCubes,
  faUniversalAccess,
  faCheckCircle,
  faUsers,
  faUser,
  faEye,
  faEyeSlash,
  faEnvelope,
  faLock
);
