import { assoc, clone, dissoc } from "rambda";
import { Module } from "vuex";

const state = {
  loadings: {},
  errors: {},
};

export enum Mutations {
  SET_LOADING = "setLoading",
  SET_LOADED = "setLoaded",
  SET_ERROR = "setError",
  SET_DISMISS_ERROR = "setDismissError",
}

export const MODULE_NAME = "app";

export const app: Module<typeof state, unknown> = {
  namespaced: true,
  state: () => state,
  getters: {
    isLoading(state) {
      return Object.keys(state.loadings).length > 0;
    },
    errors(state) {
      return state.errors;
    },
  },
  actions: {},
  mutations: {
    [Mutations.SET_LOADING](state, name) {
      state.loadings = assoc(name, true, clone(state.loadings));
    },
    [Mutations.SET_LOADED](state, name) {
      state.loadings = assoc(name, false, clone(state.loadings));
      state.loadings = dissoc(name, state.loadings);
      state.errors = dissoc(name, state.errors);
    },
    [Mutations.SET_DISMISS_ERROR](state, name) {
      state.errors = dissoc(name, state.errors);
    },
    [Mutations.SET_ERROR](state, { name, errMsg }) {
      state.loadings = assoc(name, false, clone(state.loadings));
      state.loadings = dissoc(name, state.loadings);
      state.errors = assoc(name, errMsg, clone(state.errors));
    },
  },
};
