<template>
  <div class="layout-side">
    <div class="logo">
      <div class="logo-bg" @click="goHome">
        <img :src="logo" alt="" />
      </div>
    </div>
    <div class="submenu">
      <div class="submenu-item is-active">
        <FaIcon icon="rocket" />
      </div>
      <div class="submenu-item logout" @click="logout">
        <FaIcon icon="sign-out-alt" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { accountActions } from "@src/store/modules/account/helper";
import logo from "@src/assets/images/logo.png";
</script>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MySidebar",
  components: {},
  computed: {
    route() {
      return this.$route;
    },
  },
  methods: {
    ...accountActions,
    goHome() {
      this.$router.push({ name: "p-home" });
    },
    logout() {
      this.doLogout().then(() => {
        window.location.reload();
      });
    },
  },
});
</script>

<style lang="postcss" scoped>
.layout-side {
  @apply w-12 sm:w-16 shadow-sidebar bg-white;

  & .logo {
    @apply w-12 h-12 p-2 sm:w-16 sm:h-16 sm:p-4 bg-primary-800;

    & .logo-bg {
      @apply p-1 cursor-pointer;
    }
  }

  & .submenu {
    @apply flex flex-col items-center;

    & .submenu-item {
      @apply flex items-center justify-center w-full h-12 sm:h-16;
      @apply text-neutral-300;

      &:hover {
        @apply text-dark cursor-pointer;
      }

      &.is-active {
        @apply text-primary-700 bg-neutral-200 shadow-focus;
      }
    }
  }
}
</style>
