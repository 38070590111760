<template>
  <div :class="['intro-item', classes]">
    <div class="intro-item-icon">
      <FaIcon class="w-full" :icon="icon"></FaIcon>
    </div>
    <div class="intro-item-content">
      <h3 class="intro-item-label">{{ label }}</h3>
      <p class="intro-item-desc">{{ description }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

enum Kinds {
  ICON_RIGHT = "icon-right",
  ICON_LEFT = "icon-left",
}

export default defineComponent({
  props: {
    kind: {
      type: String,
      default: Kinds.ICON_RIGHT,
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      classes: {
        "icon-right": this.kind == Kinds.ICON_RIGHT,
        "icon-left": this.kind == Kinds.ICON_LEFT,
      },
    };
  },
});
</script>

<style lang="postcss" scoped>
.intro-item {
  @apply gap-x-4 sm:flex sm:h-24;
  @apply text-center;

  &.icon-right {
    @apply sm:flex-row-reverse sm:text-right;
  }

  &.icon-left {
    @apply sm:flex-row sm:text-left;
  }

  & .intro-item-label {
    @apply my-2 text-dark text-xl font-semibold;
  }
  & .intro-item-icon {
    @apply p-2 w-11 h-11 rounded-md;
    @apply inline-block text-center text-light text-2xl bg-primary;
  }
  & .intro-item-desc {
    @apply text-sm font-normal;
    @media (min-width: 640px) {
      & {
        max-width: 20rem;
      }
    }
  }
}
</style>
