<template>
  <FaIcon class="icon-clickable" :icon="icon" @click="handleClick"></FaIcon>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
      default: "check",
    },
    onClick: {
      type: Function,
      required: true,
      default: () => () => {
        // todo
      },
    },
  },
  methods: {
    handleClick(event: any) {
      this.onClick(event);
    },
  },
});
</script>

<style lang="postcss" scoped>
.icon-clickable {
  @apply cursor-pointer;
}
</style>
