<template>
  <Section tag="header" class="header">
    <Container>
      <div class="header-body">
        <div class="logo-group">
          <router-link :to="{ name: 'p-home' }">
            <Logo :img="initLogo" class="mr-4 cursor-pointer sm:mr-8" />
          </router-link>
        </div>

        <div class="action-group">
          <div v-if="!accountIsLogged">
            <Button kind="outlined" size="small" :on-click="handleLoginButton">{{ $t("buttonAction.login") }}</Button>
          </div>
          <div v-if="accountIsLogged">
            <Button kind="outlined" size="small" :on-click="goToConsole">{{ $t("buttonAction.console") }}</Button>
          </div>
          <!-- <IconClickable class="block ml-4 text-dark text-xl md:hidden" icon="bars" :on-click="handleIconClick" /> -->
        </div>
      </div>
    </Container>
  </Section>
  <!-- <Sidebar v-model:is-open="isOpen">
    <template #title>
      <span class="pr-1 font-medium cursor-default">{{ $t("sideBar.title") }}</span>
    </template>
    <template #default> </template>
  </Sidebar> -->
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { accountActions, accountComputed } from "@src/store/modules/account/helper";
import { router } from "@src/router";
import Container from "@src/components/Container.vue";
import Section from "@src/components/Section.vue";
import Logo from "@src/components/Logo.vue";
import IconClickable from "@src/components/IconClickable.vue";
import Button from "@src/components/Button.vue";
import Sidebar from "@src/components/Sidebar.vue";
import InitLogo from "@src/assets/images/logo.png";

const images = {
  InitLogo: InitLogo,
};

export default defineComponent({
  name: "LHeader",
  components: {
    Container,
    Section,
    Logo,
    Button,
    Sidebar,
    IconClickable,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...accountComputed,
    initLogo() {
      return images.InitLogo;
    },
  },
  methods: {
    ...accountActions,
    handleIconClick() {
      this.isOpen = !this.isOpen;
    },
    handleLoginButton() {
      router.push({ name: "p-login" }).then();
    },
    goToConsole() {
      router.push({ name: "p-templates" }).then();
    },
  },
});
</script>

<style lang="postcss" scoped>
.header {
  @apply bg-secondary-100;

  & .header-body {
    @apply flex flex-row items-center justify-between;

    & .action-group {
      @apply flex flex-row items-center;
    }
  }
}
</style>
