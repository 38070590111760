<template>
  <div class="header">
    <div class="nav">
      <div class="nav-item is-active">Project "Default"</div>
    </div>
    <div class="app-version">
      interface: v1.0.0<br />
      service: v1.0.0
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MyHeader",
  components: {},
  computed: {
    route() {
      return this.$route;
    },
  },
});
</script>

<style lang="postcss" scoped>
.header {
  @apply flex items-center justify-between;
  & .app-version {
    @apply text-right text-size-1 text-light px-4;
  }

  & .nav {
    @apply h-full flex;

    & .nav-item {
      @apply flex items-center;
      @apply px-4 h-full;
      @apply text-size-2 text-light font-bold cursor-pointer;

      &:hover {
        @apply bg-primary-600;
      }

      &.is-active {
        @apply bg-primary-800;
      }
    }
  }
}
</style>
