import { createRouter, createWebHistory } from "vue-router";

import { store } from "./store";

// Layouts
import LMain from "./layouts/cms/LMain.vue";
import LApp from "./layouts/app/LApp.vue";
// Components
import PComponents from "./pages/PComponents.vue";
// CMS
import PHome from "./pages/home/PHome.vue";
import PLogin from "./pages/auth/PLogin.vue";
import PRegister from "./pages/auth/PRegister.vue";
// Application
import PApp from "./pages/app/pass-templates/PPassTemplates.vue";
import PPasses from "./pages/app/passes/PPasses.vue";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "layout",
      component: LMain,
      children: [
        {
          path: "",
          name: "p-home",
          component: PHome,
        },
        {
          path: "components",
          name: "p-components",
          component: PComponents,
        },
      ],
    },
    {
      path: "/app",
      meta: {
        private: true,
      },
      name: "layout-app",
      component: LApp,
      redirect: "/app/templates",
      children: [
        {
          path: "templates",
          name: "p-templates",
          component: PApp,
        },
        {
          path: "templates/:id/passes",
          name: "p-passes",
          component: PPasses,
        },
      ],
    },
    {
      path: "/login",
      name: "p-login",
      component: PLogin,
    },
    {
      path: "/register",
      name: "p-register",
      component: PRegister,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.private)) {
    if (store.getters["account/isLogged"]) {
      console.log("private route accepted:", store.getters["account/isLogged"]);
      next();
    } else {
      router.replace({
        name: "p-login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});
