<template>
  <aside :class="['sidebar', { active: active }]">
    <div :class="['sidebar-body', { active: active }]">
      <div :class="['sidebar-inner h-full', { active: active }]">
        <div class="sidebar-header text-dark">
          <a @click="closeSidebar">
            <slot name="title" />
          </a>
          <FaIcon class="icon-times" icon="times" @click="closeSidebar" />
        </div>
        <slot></slot>
      </div>
    </div>
    <div class="flex-grow opacity-60" @click="closeSidebar"></div>
  </aside>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MySidebar",
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      active: this.isOpen,
    };
  },
  watch: {
    isOpen: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.active !== newVal) {
          this.active = newVal;
        }
      },
    },
  },
  methods: {
    closeSidebar() {
      this.active = false;
      this.$emit("update:isOpen", this.active);
    },
  },
});
</script>

<style lang="postcss" scoped>
.sidebar {
  @apply flex w-0 bg-transparent;
  @apply fixed z-30 left-0 top-0;
  @apply hidden;
  &.active {
    @apply w-full h-full;
    @apply block;
  }
}

.sidebar-body {
  @apply w-0;
  @apply text-light bg-accent-cyan;

  &.active {
    @apply w-full h-full sm:w-1/2 lg:w-1/3;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease 0s;
  }
  & .sidebar-inner {
    @apply opacity-0 invisible;

    &.active {
      @apply opacity-100 visible;
      transition: opacity 1s;
    }
  }

  & .sidebar-header {
    & a {
      @apply flex items-center justify-center py-2 no-underline;
      -webkit-box-align: center;
    }
    & .icon-times {
      @apply absolute right-3 top-3 cursor-pointer;
    }
  }
}
</style>
