import { createNamespacedHelpers } from "vuex";
import { MODULE_NAME, Actions, Mutations } from "./index";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(MODULE_NAME);

export const passesComputed = {
  ...mapState({
    passTemplates: "passTemplates",
    passesOfTemplate: "passesOfTemplate",
    // user: "user",
  }),
  ...mapGetters({
    // accountIsLogged: "isLogged",
  }),
};

export const passesMutation = {};

export const passesActions = {
  ...mapActions([Actions.DO_GET_PASSES, Actions.DO_GET_PASS_TEMPLATES, Actions.DO_CREATE_PASS]),
};
