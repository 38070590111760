import { createI18n } from "vue-i18n";
import IconVi from "../assets/images/ico_vi.png";
import IconEn from "../assets/images/ico_en.png";
import { messages } from "./messages";

export const LOCALE = "Locale";

export const langs = {
  vi: { label: "Tiếng việt", icon: IconVi, localeCode: "vi_VN" },
  en: { label: "English", icon: IconEn, localeCode: "en_US" },
};

export const defaultLanguage = "vi";

export const i18n = createI18n({
  locale: localStorage.getItem(LOCALE) || defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages,
  // numberFormats,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});
