<template>
  <component :is="tag" :class="['button', classes]" @click="handleClick">
    <span class="label">
      <slot></slot>
    </span>
  </component>
</template>

<script lang="ts">
import { defineComponent } from "vue";

enum Kinds {
  FILLED = "filled",
  OUTLINED = "outlined",
}

enum Skins {
  NORMAL = "normal",
  PRIMARY = "primary",
}

enum Sizes {
  NORMAL = "normal",
  SMALL = "small",
  TINY = "tiny",
}

export default defineComponent({
  name: "MyButton",
  props: {
    tag: {
      type: String,
      default: "button",
    },
    kind: {
      type: String,
      default: Kinds.FILLED,
    },
    skin: {
      type: String,
      default: Skins.PRIMARY,
    },
    size: {
      type: String,
      default: Sizes.NORMAL,
    },
    onClick: {
      type: Function,
      default: () => () => {
        // todo
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      classes: {
        filled: this.kind == Kinds.FILLED,
        outlined: this.kind == Kinds.OUTLINED,
        primary: this.skin == Skins.PRIMARY,
        small: this.size == Sizes.SMALL,
        tiny: this.size == Sizes.TINY,
      },
    };
  },
  methods: {
    handleClick(event: any) {
      if (this.disabled) return;
      this.onClick(event);
    },
  },
});
</script>

<style lang="postcss" scoped>
.button {
  /* Base */
  @apply inline-block;

  &:hover {
    cursor: pointer;
  }

  /* Label - Base */
  & .label {
    @apply text-size-3 font-semibold;
    @apply sm:font-bold;
  }

  /* Size - Normal */
  @apply px-6 py-2; /* mobile */
  @apply sm:px-8 sm:py-3; /* table */

  /* Size - Small */
  &.small {
    @apply px-4 py-1; /* mobile */
    @apply sm:px-6 sm:py-2; /* table */

    & .label {
      @apply text-sm;
    }
  }

  &.tiny {
    @apply px-2 py-[0.15rem]; /* mobile */
    @apply sm:px-3 sm:py-1; /* table */

    & .label {
      @apply text-xs;
    }
  }

  /* Type - Filled */
  &.filled {
    @apply rounded;

    /* Skin - Primary */
    &.primary {
      @apply bg-accent-cyan;

      &:hover {
        @apply shadow-strong;
        filter: brightness(0.9);
      }

      & .label {
        @apply text-light;
      }
    }
  }

  /* Type - Outlined */
  &.outlined {
    @apply rounded;

    @apply border border-neutral-400;

    &:hover {
      @apply shadow-strong bg-neutral-200;
    }

    & .label {
      @apply border-dark;
    }

    /* Skin - Primary */
    &.primary {
      @apply border border-primary;

      &:hover {
        @apply shadow-strong;
      }

      & .label {
        @apply text-primary;
      }
    }
  }
}
</style>
