<template>
  <div class="input-field">
    <label v-if="label" class="label" :for="name">
      {{ label }} <span v-if="required === true" class="text-red-500">*</span>
    </label>
    <div v-if="subhead" class="text-subhead-2 text-neutral-10 mb-1">{{ subhead }}</div>
    <div :class="[classesInput, 'field', { 'is-error': errMsg != '' }]">
      <FaIcon v-if="preIcon" class="pre-icon" :icon="preIcon" />
      <slot name="pre-icon"></slot>
      <input
        ref="inputRef"
        :name="name"
        :class="['input', { 'has-icon': preIcon || $slots['pre-icon'], uppercase: uppercase }]"
        :value="value"
        :inputMode="inputMode"
        :pattern="pattern"
        :type="typeTemp"
        :placeholder="placeholder"
        :disabled="readonly"
        @input="handleChange"
      />
      <span @click="changeSuffIcon">
        <FaIcon v-if="isPass" class="suff-icon cursor-pointer" :icon="show ? 'eye' : 'eye-slash'" />
        <FaIcon v-if="suffIcon" class="suff-icon" :icon="suffIcon" />
      </span>
    </div>
    <div v-if="errMsg" class="text-body-2 text-red-600 mt-1">{{ errMsg }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export const props = {
  classesInput: {
    type: [Object, Array, String],
    default: () => {},
  },
  inputRef: {
    type: HTMLInputElement,
    default: undefined,
  },
  onChange: {
    type: Function,
    required: true,
    default: () => () => {
      // todo
    },
  },
  preIcon: {
    type: String,
    required: false,
    default: null,
  },
  suffIcon: {
    type: String,
    required: false,
    default: null,
  },
  value: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    required: false,
    default: null,
  },
  subhead: {
    type: String,
    required: false,
    default: null,
  },
  name: {
    type: String,
    required: false,
    default: null,
  },
  placeholder: {
    type: String,
    required: false,
    default: null,
  },
  pattern: {
    type: String,
    required: false,
    default: "text",
  },
  inputMode: {
    type: String,
    required: false,
    default: "text",
  },
  type: {
    type: String,
    required: false,
    default: "text",
  },
  errMsg: {
    type: String,
    required: false,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  uppercase: {
    type: Boolean,
    default: false,
  },
  isPass: {
    type: Boolean,
    default: false,
  },
};

export default defineComponent({
  props: { ...props },
  emits: ["update:inputRef"],
  watch: {
    value: {
      handler(val, old) {
        if (val !== old) {
          this.onChange(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      show: false,
      typeTemp: this.type,
    };
  },
  mounted() {
    if (this.$refs.inputRef) {
      this.$emit("update:inputRef", this.$refs.inputRef);
    }
  },
  methods: {
    handleChange(event: any) {
      this.onChange(event.target.value);
    },
    changeSuffIcon(event: any) {
      if (this.isPass) {
        this.show = !this.show;
        this.typeTemp = this.show ? "text" : "password";
      } else {
        this.onChange(event.target.value);
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
.label {
  @apply w-full text-sm font-semibold text-dark;
}

.input-field {
  & .field {
    @apply flex flex-row h-12 items-center px-0.5 py-2 border-b border-neutral-300;
    @apply focus-within:border-b-primary focus-within:border-b-2;

    & .input {
      @apply w-full outline-none bg-transparent text-dark;
      &.has-icon {
        @apply ml-2;
      }
    }

    & .pre-icon {
      @apply text-neutral-400;
    }

    & .suff-icon {
      @apply text-neutral-400 ml-2;
    }
  }

  & .is-error {
    @apply border-red-500;
  }
}
</style>
