<template>
  <div :class="['box', classes]">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

enum Sizes {
  NORMAL = "normal",
  SMALL = "small",
}

export default defineComponent({
  name: "MyBox",
  props: {
    size: {
      type: String,
      default: Sizes.NORMAL,
    },
  },
  data() {
    return {
      classes: {
        small: this.size == Sizes.SMALL,
      },
    };
  },
  methods: {},
});
</script>

<style lang="postcss" scoped>
.box {
  max-width: 40rem;

  &.small {
    max-width: 20rem;
  }
}
</style>
