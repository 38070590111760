import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Store } from "vuex";
import jwtDecode, { JwtPayload } from "jwt-decode";

import { makeid } from "@src/lib/id";
import { MODULE_NAME as appModule, Mutations as appMutations } from "@src/store/modules/app/index";
import { MODULE_NAME as accountModule, Actions as accountActions } from "@src/store/modules/account";

export const TOKEN_KEY = "token";

interface ClientRequestCtx {
  id?: string;
  action?: string;
  showLoading?: boolean;
  showError?: boolean;
}

interface ConfigContext {
  context: ClientRequestCtx;
}

type HTTPReqCfgWithCtx = AxiosRequestConfig & ConfigContext;

const defaultCtx: ClientRequestCtx = {
  showError: true,
  showLoading: true,
};

export function newCtxConfig(ctx: ClientRequestCtx, config?: AxiosRequestConfig): AxiosRequestConfig {
  if (config) {
    (config as HTTPReqCfgWithCtx).context = { ...defaultCtx, ...ctx };
    return config;
  }

  return { context: ctx } as any;
}

class HTTP {
  static instance?: HTTP;

  constructor() {
    if (!HTTP.instance) {
      HTTP.instance = this;
    }
    return HTTP.instance;
  }

  baseURL: string = "https://app-wallet-pass-engine.init.vn";

  client: AxiosInstance = axios.create({
    // baseURL: process.env.API_HOST,
    baseURL: this.baseURL,
  });

  loadedToken: string = "";

  parseCtx(config: HTTPReqCfgWithCtx) {
    if (config.context) {
      return { ...defaultCtx, ...config.context };
    }

    return defaultCtx;
  }

  withStore(store: Store<unknown>) {
    this.client.interceptors.request.use(
      (config) => {
        const cfg = config as HTTPReqCfgWithCtx;
        const ctx = this.parseCtx(cfg);

        cfg.context = ctx;

        if (!ctx.id) {
          ctx.id = makeid(10);
        }

        if (ctx.showLoading) {
          store.commit(`${appModule}/${appMutations.SET_LOADING}`, `${ctx.action}/${ctx.id}`);
        }

        return config;
      },
      (error: AxiosError) => {
        const err = error;
        const ctx = this.parseCtx(err.config as HTTPReqCfgWithCtx);

        if (ctx.showError) {
          store.commit(`${appModule}/${appMutations.SET_ERROR}`, {
            name: `${ctx.action}/${ctx.id}`,
            errMsg: err.message,
          });
        }

        return Promise.reject(error);
      }
    );

    this.client.interceptors.response.use(
      (resp) => {
        const ctx = this.parseCtx(resp.config as HTTPReqCfgWithCtx);

        if (ctx.showLoading) {
          store.commit(`${appModule}/${appMutations.SET_LOADED}`, `${ctx.action}/${ctx.id}`);
        }

        return resp;
      },
      (err: AxiosError) => {
        const { status } = err.response as AxiosResponse;

        if (status === 401) {
          store.dispatch(`${accountModule}/${accountActions.DO_LOGOUT}`);
        }

        const ctx = this.parseCtx(err.config as HTTPReqCfgWithCtx);
        const { data } = err.response as AxiosResponse;

        const errMsg = data.message;

        if (ctx.showError) {
          store.commit(`${appModule}/${appMutations.SET_ERROR}`, {
            name: `${ctx.action}/${ctx.id}`,
            errMsg: errMsg,
          });
        }

        return Promise.reject(err);
      }
    );
  }

  loadTokenFromStorage(): boolean {
    const jwt = localStorage.getItem(TOKEN_KEY);
    if (!jwt) {
      return false;
    }

    const jwtPayload = jwtDecode<JwtPayload>(jwt);
    let expiredTime = jwtPayload["exp"];
    if (!expiredTime) {
      expiredTime = 0;
    }
    const current = new Date().getTime() / 1000;

    if ((expiredTime - current) / (24 * 3600) <= 1) {
      return false;
    }

    this.useToken(jwt);
    this.loadedToken = jwt;
    return true;
  }

  useToken(jwt: string) {
    this.client.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  }

  saveToken(jwt: string) {
    localStorage.setItem(TOKEN_KEY, jwt);
    this.useToken(jwt);
  }

  deleteToken() {
    localStorage.removeItem(TOKEN_KEY);
    delete this.client.defaults.headers.common["Authorization"];
  }
}

const http = new HTTP();

export { http };
